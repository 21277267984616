import React,{ useState } from 'react';
import { Container, Typography, Box, Paper, Tabs, Tab } from '@mui/material';
import UpdateGptUrl from './gptUrlSetting/UpdateGptUrl';
import ManualPayment from './gptUrlSetting/manualPayment';
import ClosedBotId from './gptUrlSetting/closebotId';
import ExtendTrial from './gptUrlSetting/extenttrial';
const Home = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
<Paper elevation={0} sx={{ padding: '20px', marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Tabs value={activeTab} onChange={handleTabChange} centered>
    <Tab label="Gpt Url" />
    <Tab label="Manual Payment" />
    <Tab label="Close Bot" />
    <Tab label="Extend Trial" />
  </Tabs>
  {activeTab === 0 && <UpdateGptUrl />}
  {activeTab === 1 && <ManualPayment />}  
  {activeTab === 2 && <ClosedBotId />}
  {activeTab === 3 && <ExtendTrial />}
</Paper>
  );
};

export default Home;
