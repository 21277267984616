import logo from './logo.svg';
import './App.css';

import Routess  from './home/router';
function App() {
  return (
  <Routess/>
  );
}

export default App;
