import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, Container, Box, Grid, Paper, InputAdornment, InputLabel, MenuItem, Select, FormControl } from '@mui/material';

const ManualPayment = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 30);
  const [formData, setFormData] = useState({
    status: 'complete',
    botId: '',
    nextBillingDate: currentDate.toISOString().split('T')[0],
    email: '',
    plan: 'Premium Plan',
    planPeriod: 'monthly',
    netPrice: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [planPeriod, setPlanPeriod] = useState('monthly');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function calculateNextBillingDate(period) {
    const currentDate = new Date();
    let nextDate;
    if (period === 'monthly') {
      nextDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    } else if (period === 'yearly') {
      nextDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
    }
    return nextDate.toISOString().split('T')[0]; // Return as YYYY-MM-DD format
  }

  function handleStatusChange(status) {
    const currentDate = new Date();
    let newBillingDate = '';

    if (status === 'trial') {
      newBillingDate = new Date(currentDate.setDate(currentDate.getDate() + 7)).toISOString().split('T')[0];
    } else if (status === 'canceled') {
      newBillingDate = currentDate.toISOString().split('T')[0];
    } else if (status === 'complete') {
      newBillingDate = calculateNextBillingDate(planPeriod);
    }

    setFormData({
      ...formData,
      status: status,
      nextBillingDate: newBillingDate
    });
  }

  const handlePlanPeriodChange = (event) => {
    const selectedPeriod = event.target.value;
    setPlanPeriod(selectedPeriod);
    const newBillingDate = calculateNextBillingDate(selectedPeriod);
    setFormData({
      ...formData,
      planPeriod: selectedPeriod,
      nextBillingDate: newBillingDate
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'status') {
      handleStatusChange(value);
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data Submitted:', formData);
    try {
      const response = await fetch('https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/admin/api/manualPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': "6543j-84b29-342dq234-04sdbcbd60721",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        setSuccess(true);
      } else {
        console.log(response);
      }
    } catch (error) {
      const message = error.response?.data?.error || 'An error occurred. Please try again.';
      setErrorMessage(`Error: ${message}`);
      setError(true);
    }
  };

  return (
    <Container>
      <Box mt={4}>
        <Paper elevation={3}>
          <Box p={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    select
                  >
                    <MenuItem value="complete">Complete</MenuItem>
                    <MenuItem value="canceled">Canceled</MenuItem>
                    <MenuItem value="trial">Trial</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Bot ID"
                    name="botId"
                    value={formData.botId}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                {/* Conditionally render these fields based on the status */}
                {formData.status !== 'canceled' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="date"
                        label="Next Billing Date"
                        name="nextBillingDate"
                        value={formData.nextBillingDate}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {formData.status !== 'trial' && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="plan-period-select-label">Choose a Plan Period</InputLabel>
                            <Select
                              labelId="plan-period-select-label"
                              id="plan-period-select"
                              value={planPeriod}
                              label="Choose a Plan Period"
                              onChange={handlePlanPeriodChange}
                            >
                              <MenuItem value="monthly">Monthly</MenuItem>
                              <MenuItem value="yearly">Yearly</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Amount"
                            name="netPrice"
                            type="text"
                            value={formData.netPrice}
                            onChange={handleChange}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              inputProps: { inputMode: 'numeric', pattern: '[0-9]*' }
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
      <Snackbar open={success} autoHideDuration={5000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Manual Payment Done
        </Alert>
      </Snackbar>

      <Snackbar open={error} autoHideDuration={5000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ManualPayment;
