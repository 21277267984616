import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Snackbar, Alert, Container, Box, Grid, Paper } from '@mui/material';

const ClosedBotId = () => {
  const [botId, setBotId] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        'https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/admin/api/closeBotId',
        {
          BotId: botId,
          email: email,
        },
        {
          headers: {
            'Api-Key': "6543j-84b29-342dq234-04sdbcbd60721",
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
      }
      else{
        console.log(response)
      }
    } catch (error) {
      const message = error.response?.data?.error || 'An error occurred. Please try again.';
      setErrorMessage(`Error: ${message}`);
      setError(true);
    }
  };
  return (
    <Container maxWidth="sm">
        <Paper elevation={3} sx={{ padding: '20px' }}>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Bot ID"
                  variant="outlined"
                  value={botId}
                  onChange={(e) => setBotId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="API Key"
                  variant="outlined"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>

      <Snackbar open={success} autoHideDuration={5000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
          BotId Closed !
        </Alert>
      </Snackbar>

      <Snackbar open={error} autoHideDuration={5000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ClosedBotId;
